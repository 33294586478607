import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import withIncrements from './withIncrements';
import withMedia from './withMedia';
import vars from '../lib/styled';

const BlockQuoteContainer = styled.div`
    margin: 6rem auto 0 auto;
    height: 300px;
    position: relative;
    width: 650px;
    
    ${withMedia('laptop', css`
        width: 500px;
    `)}

    ${withMedia('tablet', css`
        height: 450px;
        width: 70%;
    `)}
`;

const Blockquote = styled(
    posed.blockquote({
        init: { left: 0, top: 0 },
        enter: { opacity: 1 },
        exit: { opacity: 0 }
    }))`
    left: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;

    &::before {
        content: "“";
        color: ${vars.primary};
        display: block;
        font-family: serif;
        font-size: 130px;
        height: 65px;
        line-height: 65px;
        text-align: center;
        width: 100%;
    }
`;

const Cite = styled.cite`
    display: block;
    font-weight: bolder; 
    font-style: normal;
    margin-top: 2rem;
    padding: 3rem;
    position: relative;

    &::before {
        border-top: 2px solid ${vars.primary};
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 1rem;
        transform: translateX(-50%);
        width: 100px;
    }

    ${withMedia('phone', css`
        padding: 1rem;

        &::before { display: none}
    `)}
`;

const Testimonial = ({ num, quotes }) => (
    <BlockQuoteContainer>
        <PoseGroup animateOnMount>
            {quotes.map((obj, i) =>
                num === i && <Blockquote key={i}>
                    {obj.quote.quote}
                    <Cite>{obj.person} - {obj.company}</Cite>
                </Blockquote>
            )}
        </PoseGroup>
    </BlockQuoteContainer>
);

export default withIncrements(Testimonial);


