import React from 'react';
import App from './_app';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Slider from '../components/Slider';
import Testimonial from '../components/Testimonial';
import Panel from '../components/Panel';
import Container, { ContainerLarge, ContainerMedium } from '../components/Container';
import Grid, { Third, TwoThirds } from '../components/Grid';
import Wrap from '../components/Wrap';
import Button, { ExternalLink } from '../components/Button';
import { Mutation } from 'react-apollo';
import Alert from '../components/Alert';
import TalentForm from '../forms/talent';
import { INQUIRY } from '../queries/public';
import { Lead } from '../components/Hero';

const Index = ({ data }) => (<App>
    <Helmet>
        <title>{data.site.siteMetadata.seoTitle}</title>
        <meta name="description" content={data.site.siteMetadata.seoDescription} />
    </Helmet>
    <header>
        <Slider
            count={data.contentfulHome.slides.length}
            slides={data.contentfulHome.slides}
        />
    </header>

    <Wrap muted style={{ padding: '2rem 1rem' }}>
        <ContainerMedium>
            <h2 style={{ fontSize: '2.1em', textAlign: 'center' }}>
                <i>{data.contentfulHome.description.description}</i>
            </h2>
            <p style={{ textAlign: 'center' }}>
                <Button
                    primary
                    to="/talent"
                    style={{
                        display: 'inline-block',
                    }}
                >
                    {'Inquire Now'}
                </Button>
            </p>
        </ContainerMedium>
    </Wrap>

    <Wrap>
        <ContainerLarge>
            <Grid>
                <Third>
                    <h3 style={{ margin: '1rem 0' }}>
                        {data.contentfulHome.callout.callout}
                    </h3>
                    <hr />
                </Third>
                <TwoThirds>
                    <div dangerouslySetInnerHTML={{ __html: data.contentfulHome.childContentfulHomeBodyRichTextNode.childContentfulRichText.html }} />
                    {/* <ExternalLink secondary href="https://jobs-flexstaf-it.netlify.app/">Job Search</ExternalLink> */}
                    <ExternalLink secondary href="https://www.careers-page.com/flexstaf-it">Job Search</ExternalLink> 
                    <Button secondary to="/resume">Submit Resume</Button>
                </TwoThirds>
            </Grid>
        </ContainerLarge>
    </Wrap>

    <ContainerLarge style={{ textAlign: 'center' }}>
        <Testimonial
            count={data.contentfulHome.testimonials.length}
            quotes={data.contentfulHome.testimonials}
        />
    </ContainerLarge>
    <ContainerMedium>
        <Grid>
            {data.contentfulHome.links.map((obj, i) =>
                <Panel
                    key={i}
                    data={obj}
                    image={obj.image}
                />
            )}
        </Grid>
    </ContainerMedium>
</App>);

export default Index;
export const query = graphql`
    query {
        site {
            siteMetadata {    
                seoTitle 
                seoDescription
            }
        }

        contentfulHome {
            title 
            description {
                description
            }
            callout {
                callout 
            }
            links {
                title 
                lead {
                    lead
                }
                url 
                icon
                image {
                    title
                    fluid(maxWidth: 1200, quality: 100){
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
                parent {
                ...on ContentfulPage {
                        url
                    }
                }
            }
            childContentfulHomeBodyRichTextNode {
                childContentfulRichText {
                    html
                }
            }
            testimonials {
                person 
                company 
                quote {
                    quote
                }
            }
            slides {
                title
                subtitle
                childContentfulSliderBodyRichTextNode {
                    childContentfulRichText {
                        html
                    }
                }
                link {
                    url 
                    parent {
                    ...on ContentfulPage {
                            url
                        }
                    }
                }
                image {
                    title
                    fluid(maxWidth: 1200, quality: 100){
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }
        }
    }   
`;
